import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";

import theme from "../../theme";
import mq from "../../utils/mediaQuery";
import CaseItem from "../CaseItem/v2";
import ContentWrapper from "../ContentWrapper";

const StyledContentWrapper = styled(ContentWrapper)`
  ${mq("4")} {
    max-width: ${theme.spacing("grid[12]")};
  }
`;

const Wrapper = styled.div`
  width: 100%;
  padding-top: ${theme.space("xxs")};
  padding-bottom: ${theme.space("xl")};
  background-color: ${({ background }) =>
    background ? theme.color(background) : "none"};
`;

const CasesWrapper = styled.div`
  position: relative;
  margin-top: ${theme.space("xl")};

  ${mq("2")} {
    display: grid;
    grid-gap: 0 ${theme.space("xl")};
    grid-template-columns: 1fr 1fr;

    > *:nth-of-type(even) {
      transform: ${({ multipleRows }) =>
        multipleRows ? "translateY(100px)" : "translateY(0)"};
    }
  }

  ${mq("4")} {
    grid-gap: 0 ${theme.space("xxxl")};
    padding: 0 ${theme.space("xl")};
  }
`;

const StyledCaseItem = styled(CaseItem)`
  margin: ${theme.space("m")} 0;

  ${mq("2")} {
    margin: ${theme.space("l")} 0;
  }

  ${mq("3")} {
    margin: ${theme.space("xl")} 0;
  }
`;

const HeadingWrapper = styled.div`
  position: relative;
  z-index: 1;
  margin-bottom: ${theme.spacing("xl")};
  max-width: 460px;

  ${mq("2")} {
    padding-top: ${theme.space("xl")};
  }

  ${mq("3")} {
    padding-top: ${theme.space("xxl")};
  }

  ${mq("4")} {
    padding-top: ${theme.space("xxxl")};
  }
`;

function CaseGrid({ items, heading, backgroundColor, payOff }) {
  return (
    <Wrapper background={backgroundColor}>
      <StyledContentWrapper>
        {heading && <HeadingWrapper>{heading}</HeadingWrapper>}
        {items && (
          <CasesWrapper multipleRows={items.length > 2}>
            {items.map((caseItem, i) => (
              <StyledCaseItem key={i} service={"title"} {...caseItem} />
            ))}
          </CasesWrapper>
        )}
        {payOff}
      </StyledContentWrapper>
    </Wrapper>
  );
}

CaseGrid.propTypes = {
  items: PropTypes.array,
  heading: PropTypes.node,
  payOff: PropTypes.node,
  backgroundColor: PropTypes.string,
};

export default CaseGrid;
